import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  //marginTopSubstitute,
  //smallMarginTopSubstitute,
  introSection,
  buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const NetzwocheArtikelPage = () => {
  return (
    <Layout pageTitle="NetzwocheArtikel" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                 <p className={description}>Fachartikel - 02. Mai 2024</p> 
                <h1>Smart reicht nicht mehr, die Zukunft des digitalen Spitals ist «liquid»</h1>
               
                <p className="text-medium">
                Ein Artikel aus der Netzwoche. Eine Leseempfehlung von uns.</p>

                <p>Moderne Spitäler sollten ihre Patientinnen und Patienten über die Spitalgrenzen hinweg betreuen. 
                  Ein wesentlicher Baustein hierfür ist der sichere und nahtlose Datenfluss in den Spitälern und
                  darüber hinaus. Als Lösung bietet sich eine cloudbasierte Integrationsplattform an.</p>
                <div className={buttonGroup}><a href="https://www.netzwoche.ch/news/2024-05-02/smart-reicht-nicht-mehr-die-zukunft-des-digitalen-spitals-ist-liquid"className="button-outline blue" target="_blank" rel="noreferrer">Zum Artikel</a> </div>
                <div style={{ marginBottom: 40 }} className={buttonGroup}><a href="https://future.hospital/?trk=public_post-text"className="button-outline blue" target="_blank" rel="noreferrer"> Mehr zum Innosuise SHIFT Projekt</a> </div>

                <p>Bleiben Sie über neueste Informationen und Veranstaltungen von the i-engineers stets informiert und melden Sie sich für unseren Newsletter an.</p>
                <div className={buttonGroup}><a 
                    href="https://www.tie.ch/landingpages/newsletter-anmeldung"
                    className="button-outline blue" 
                    target="_blank" 
                    rel="noreferrer"> Zur Newsletter Anmeldung </a> </div>
                  </div>
                
           
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default NetzwocheArtikelPage;
